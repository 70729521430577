import React, { useEffect, useState } from 'react';
import { ViewTitle } from 'components/ViewTitle';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router';
import FormSetting from '../../components/Forms/FormSetting';
import * as actions from '../../redux/action';
import { Box, Button, Modal, TextField } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function SystemSetting() {
  const dispatch = useDispatch();
  const setting = useSelector((state) => state.setting);
  const history = useHistory();
  const [open, setOpen] = useState(true);
  const [password, setPassword] = useState('');
  const { control, handleSubmit, setValue } = useForm({
    'moduleAccess.FND': setting?.arr?.moduleAccess?.FND,
  });

  useEffect(() => {
    dispatch(actions.settingGetAll({}));
    return () => {};
  }, []);

  useEffect(() => {
    setValue('moduleAccess.FND', setting?.arr?.moduleAccess?.FND);
    setValue('moduleAccess.SLD', setting?.arr?.moduleAccess?.SLD);
    setValue('moduleAccess.HRD', setting?.arr?.moduleAccess?.HRD);
    setValue('moduleAccess.PCD', setting?.arr?.moduleAccess?.PCD);
    setValue('moduleAccess.PDD', setting?.arr?.moduleAccess?.PDD);
    setValue('moduleAccess.ADM', setting?.arr?.moduleAccess?.ADM);
    setValue('moduleAccess.PM', setting?.arr?.moduleAccess?.PM);
    setValue('calendarAll', setting?.arr?.calendarAll);
    setValue('calendarMe', setting?.arr?.calendarMe);
    setValue('multipleApplover', setting?.arr?.multipleApplover);
    setValue('financeTable', setting?.arr?.financeTable);
    setValue('salaryslip.typeOne', setting?.arr?.salaryslip?.typeOne);
    setValue('salaryslip.typeTwo', setting?.arr?.salaryslip?.typeTwo);
    return () => {};
  }, [setting]);

  const checkPassword = () => {
    if (password === 'IARCeimhtsj4') {
      setOpen(false);
    } else {
      alert('รหัสผ่านผิด');
      setPassword('');
    }
  };

  const renderModal = () => (
    <Modal
      open={open}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box sx={style}>
        <div>
          <div className="text-2xl text-center font-bold text-green-500 ">
            IARC-DEV
          </div>
          <div>
            <div className="text-center py-2 ">
              หากต้องการเปิดการใช้งานโมดูลเพิ่มเติม สามารถติดต่อผู้ดูแลระบบ
              เบอร์ 094-806-9015
            </div>
          </div>
          <div className="w-full py-4  flex justify-center">
            <TextField
              id="outlined-basic"
              label="รหัสผ่าน"
              type="password"
              size="small"
              variant="outlined"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="flex justify-center gap-2 ">
            <Button variant="contained" onClick={() => checkPassword()}>
              เข้าใช้งาน
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => history.goBack()}
            >
              กลับ
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );

  const onSubmit = async (data) => {
    try {
      await dispatch(actions.settingPut(setting?.arr?._id, data));
      await dispatch(actions.settingGetAll({}));
    } catch (error) {
      console.log('error');
    }
  };

  const renderForm = () => (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormSetting
          control={control}
          Controller={Controller}
          setting={setting}
        />
      </form>
    </div>
  );

  const renderTitle = () => <ViewTitle title="ตั้งค่าระบบ" />;

  return (
    <div>
      {/* {renderModal()} */}
      {renderTitle()}
      <div>{renderForm()}</div>
    </div>
  );
}

export default SystemSetting;
